import type DataObject from 'o365.modules.DataObject.ts';

export default class Hierarchy {
    private _dataObject: DataObject
    private _idField: string;
    private _parentField: string;
    private _hasNodesField: string;
    private _levelField: string;
    private _storage: any;

    private _startingLevel = 0;
    private _deepestLevel: number;

    updated = new Date();

    constructor(options: {
        dataObject: DataObject,
        parentField?: string,
        idField?: string,
        hasNodesField?: string,
        levelField?: string
    }) {
        this._dataObject = options.dataObject;
        this._idField = options.idField ?? 'ID';
        this._parentField = options.parentField;
        this._hasNodesField = options.hasNodesField;
        this._levelField = options.levelField;

        this._storage = {};
    }

    setupFields(options: {
        parentField?: string,
        idField?: string,
        hasNodesField?: string,
        levelField?: string
    }) {
        this._idField = options.idField ?? this._idField;
        this._parentField = options.parentField ?? this._parentField;
        this._hasNodesField = options.hasNodesField ?? this._hasNodesField;
        this._levelField = options.levelField ?? this._levelField;
    }

    async loadDetails(item: any, index: number, emitDataLoaded = true) {
        if ((item.o_expanded || item.o_loading) && emitDataLoaded) { return; }
        item.o_loading = true;
        if (index == null) {
            index = this._getDataIndex(item);
        }

        let dataToReturn: any[];

        if (this._storage[this.getId(item)]) {
            // this._dataObject['_data'].splice(index + 1, 0, ...this._storage[this.getId(item)]);

            dataToReturn = this._storage[this.getId(item)];
        } else {
            const options = this._dataObject.recordSource.getOptions();
            options.whereClause = options.whereClause
                ? `(${options.whereClause}) AND (${this._parentField} = ${item[this._idField]})`
                : `${this._parentField} = ${item[this._idField]}`;

            const data = <any[]>await this._dataObject.dataHandler.request('retrieve', options);
            const preparedData = data.map(rawItem => this._transformItem(rawItem, item['o_level'] + 1));

            const storageData = this._dataObject.storage.setItems(preparedData, false);

            const key = item[this._idField];
            this._storage[key] = storageData;
            dataToReturn = storageData;
        }

        item['o_expanded'] = true;
        if (this._dataObject.data[index]['index'] !== item.index) {
            index = this._getDataIndex(item);
        }
        this._dataObject['_data'].splice(index + 1, 0, ...dataToReturn);

        const promises = this._storage[this.getId(item)].filter(x => x.o_expanded).map(itemToLoad => this.loadDetails(itemToLoad, null, false))
        await Promise.all(promises);

        if (emitDataLoaded) {
            this._dataObject.emit('DataLoaded', this._dataObject.data);
            this.update();
        }
        delete item.o_loading;
        return dataToReturn;
    }

    async loadTopNodes() {
        this._dataObject.state.isLoading = true;
        this._storage = {};
        const options = this._dataObject.recordSource.getOptions();

        options.whereClause = options.whereClause
            ? `(${options.whereClause}) AND (${this._parentField} IS NULL)`
            : `${this._parentField} IS NULL`;

        const data = <any[]>await this._dataObject.dataHandler.request('retrieve', options);

        const preparedData = data.map(item => this._transformItem(item));

        const storageData = this._dataObject.storage.setItems(preparedData, true);
        storageData[0].current = true;
        this._dataObject.setData(storageData, true);

        this._dataObject.state.isLoading = false;
        this._dataObject.state.isLoaded = true;

        this._dataObject.emit('DataLoaded', this._dataObject.data);
        this.update();

        return storageData;
    }

    collapseDetails(item: any, index: number) {
        if (index == null) {
            index = this._getDataIndex(item);
        }
        const currentLevel = item['o_level'];
        let lastIndex;
        for (let i = index + 1; i < this._dataObject.data.length; i++) {
            const row = this._dataObject.data[i];
            if (row['o_level'] <= currentLevel) {
                lastIndex = i - 1;
                break;
            }
        }

        if (lastIndex == null) { lastIndex = this._dataObject.data.length - 1; }

        item['o_expanded'] = false;
        // this._dataObject.setCurrentIndex(item.index, true);
        const collapsedRows = this._dataObject['_data'].splice(index + 1, lastIndex - index);
        this.update();
        // collapsedRows.forEach(row => {
        //     if (row['o_expanded']) {
        //         row['o_expanded'] = false;
        //     }
        // });

    }

    async doSearch(value) {
        console.log(value)
        const options = this._dataObject.recordSource.getOptions();

        options.filterString = `OrgUnit LIKE '%${value}%'`;

        const data = <any[]>await this._dataObject.dataHandler.request('retrieve', options);

        const pathList = data.map(x => `'${x.IdPath}'`).join(',');

        options.filterString = `IdPath IN (${pathList})`;

        const fullData = <any[]>await this._dataObject.dataHandler.request('retrieve', options);

        const storageData = this._dataObject.storage.setItems(fullData, true);
        storageData[0].current = true;
        this._dataObject.setData(storageData, true);
        this.update();

        return storageData;
    }

    async expandAll() {
        if (!this._hasNodesField) {
            console.warn('HasNodes field should be defined when using expand all, will attempt to load details for all records now');
        }
        const promises = [];

        const dataCopy = [...this._dataObject.data];
        let index = 0;
        while (index < dataCopy.length) {
            const item = dataCopy[index];
            if (item['o_hasDetails'] && !item['o_expanded']) {
                promises.push(this.loadDetails(item, null));
            }
            index++;
        }

        return Promise.all(promises);
    }

    async expandToLevel(level: number) {
        if (!this._hasNodesField) {
            console.warn('HasNodes field should be defined when using expand to level, will attempt to load details for all records now');
        }

        let index = 0;
        while (index < this._dataObject.data.length) {
            const item = this._dataObject.data[index];
            if (item['o_hasDetails'] && !item['o_expanded'] && item['o_level'] <= level) {
                await this.loadDetails(item, index);
            }
            index++;
        }
    }

    collapseAll() {
        // for (let i = this._deepestLevel; i >= 0; i--) {
        //     this.collapseLevel(i);
        // }

        this._dataObject['_data'] = this._dataObject.data.reduce((arr, item) => {
            if (item['o_expanded']) {
                item['o_expanded'] = false;
            }
            if (item['o_level'] === this._startingLevel) {
                arr.push(item);
            }
            
            return arr;
        }, []);
        this.update();
    }

    collapseLevel(level: number) {
        this._dataObject.data.filter(x => x['o_level'] === level).forEach(item => this.collapseDetails(item, null));
    }


    private getId(item: any) {
        if (item) { return item[this._idField]; }
    }

    private getParentId(item: any) {
        if (item) { return item[this._parentField]; }
    }

    private _transformItem(item: any, level = 0) {
        item['o_hasDetails'] = item[this._hasNodesField] ?? true;

        item['o_level'] = item[this._levelField] ?? item['o_level'] ?? level ?? 0;
        if (!this._deepestLevel || item['o_level'] > this._deepestLevel) { this._deepestLevel = item['o_level']; }
        if (item['o_level'] < this._startingLevel) { this._startingLevel = item['o_level']; }

        if (this._storage[this.getId(item)]) {
            item['o_expanded'] = true;
        }

        return item;
    }

    private _getDataIndex(item) {
        return this._dataObject.data.findIndex(x => item[this._idField] === x[this._idField]);
    }

    private update() {
        this.updated = new Date();
    }

}